import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { KeyboardArrowDown as DownIcon } from 'styled-icons/material/KeyboardArrowDown'

import { Image } from '../common/Image'
import { Organization, User } from '../context/Api'
import { AuthContext } from '../context/Auth'

export const SharedNavbar: React.FC = observer(() => {
  const auth = useContext(AuthContext)

  if (!auth.me) {
    return <div />
  }

  return (
    <WhiteNavbar>
      <Container>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>

        <Nav className="mr-right">
          <NavItem>
            <Nav.Link href="https://github.com/codechecks/docs">Docs</Nav.Link>
          </NavItem>

          {auth.org && (
            <UserDropdown
              user={auth.me}
              org={auth.org}
              switchOrg={(o) => {
                auth.org = o
              }}
              logout={() => auth.logout()}
            />
          )}
        </Nav>
      </Container>
    </WhiteNavbar>
  )
})

const Logo = styled((props) => <Image path="logo/logo" {...props} />)`
  height: 40px;
`

const WhiteNavbar = styled(Navbar)`
  background-color: white;
`

const NavItem = styled(Nav.Item)`
  .nav-link {
    color: #242121 !important;
  }
  margin-right: 10px;
`

const UserDropdown: React.FC<{
  user: User
  org: Organization
  switchOrg: (org: Organization) => any
  logout: () => any
}> = ({ user, org, switchOrg, logout }) => {
  return (
    <StyledDropdown>
      <NavDropdown title={<UserInfo org={org} />} id="basic-nav-dropdown">
        {user.orgs.map((org) => (
          <NavDropdown.Item key={org.id} onClick={() => switchOrg(org)}>
            {org.login}
          </NavDropdown.Item>
        ))}
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={logout}>Sign out</NavDropdown.Item>
      </NavDropdown>
    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  .dropdown-toggle.nav-link {
    padding-top: 0;
    padding-bottom: 0;

    ::after {
      display: none;
    }
  }
`

const UserInfo: React.FC<{ org: Organization }> = (props) => {
  return (
    <UserInfoWrapper>
      <Avatar src={props.org.avatarUrl} />
      <Name login={props.org.login} fullName={undefined} />

      <DownIconStyled size={30} />
    </UserInfoWrapper>
  )
}

const Avatar = styled.img`
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
`

const Name: React.FC<{ login: string; fullName?: string }> = ({ login, fullName }) => {
  if (!fullName) {
    return <MainName>{login}</MainName>
  }
  return (
    <StyledDiv>
      <MainName>{fullName}</MainName>
      <br />
      <SecondaryName>{login}</SecondaryName>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
`

const MainName = styled.span`
  font-weight: bold;
  color: #242121;
  font-size: 14px;
`

const SecondaryName = styled.span`
  color: #242121;
  font-size: 13px;
`

const DownIconStyled = styled(DownIcon)`
  color: #242121;
  margin-left: 7px;
`

const UserInfoWrapper = styled.div`
  display: inline-block;
`
