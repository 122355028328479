import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { AuthorizedLayout } from './components/Authorized/AuthorizedLayout'
import { AuthContext } from './components/context/Auth'
import { GithubCallback } from './components/Login/GithubCallback'
import { LoginView } from './components/Login/Login'
import { ProjectList } from './components/project-list/ProjectList'

export const App: React.FC = () => {
  return (
    <Router>
      <Route exact path="/" component={RootView} />
      <Route path="/github-callback" component={GithubCallback} />
    </Router>
  )
}

const RootView: React.FC = observer(() => {
  const auth = useContext(AuthContext)
  console.log('Rerender!')

  if (auth.isAuthorized) {
    return (
      <AuthorizedLayout>
        <AuthorizedRoutes />
      </AuthorizedLayout>
    )
  } else {
    return <LoginView />
  }
})

const AuthorizedRoutes: React.FC = () => (
  <>
    <Route exact path="/" component={ProjectList} />
  </>
)
