import { createContext } from 'react'
import { DeepReadonly } from 'ts-essentials'

import { getEnv, getRequiredEnv } from '../../utils/env'

export function getConfig() {
  return {
    api: {
      url: getEnv('REACT_APP_CC_HOST') || '/api/v1',
      fullUrl: getRequiredEnv('REACT_APP_FULL_API_URL') + 'v1/',
    },
    github: {
      clientId: getRequiredEnv('REACT_APP_GH_CLIENT_ID'),
      appName: getRequiredEnv('REACT_APP_GH_APP_NAME'),
    },
  }
}

export type Config = DeepReadonly<ReturnType<typeof getConfig>>

export const config = getConfig()
export const ConfigContext = createContext(config)
