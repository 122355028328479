// in prod mode we inject envs as window.envs otherwise they get rewritten by webpack. This could be solved after CRA reject.

export function getRequiredEnv(name: string): string {
  const value = process.env[name] || (window as any).envs[name]
  if (value === undefined) {
    throw new Error(`Required env var ${name} missing`)
  }

  return value
}

export function getEnv(name: string): string | undefined {
  return process.env[name] || ((window as any).envs || {})[name]
}

export function getIntegerEnv(name: string, _default: number): number {
  const value = process.env[name] || (window as any).envs[name]

  if (value === undefined) {
    return _default
  }

  const parsedValue = parseInt(value)
  if (isNaN(parsedValue)) {
    throw new Error(`Env ${name} can't be parsed as integer`)
  }
  return parsedValue
}

export function getBooleanEnv(name: string, def: boolean): boolean {
  const value = process.env[name] || (window as any).envs[name]

  if (value === undefined) {
    return def
  }

  if (value === '1') {
    return true
  } else {
    return false
  }
}
