/* eslint-disable */
import React, { useContext } from 'react'

import styled from 'styled-components'
import { SharedNavbar } from './Navbar'
import { Container } from 'react-bootstrap'
import { ConfigContext } from '../context/Config'

export const AuthorizedLayout: React.FC = (props) => {
  const config = useContext(ConfigContext)

  return (
    <MainWrapper>
      <>
        <SharedNavbar />
        <MainContainer>{props.children}</MainContainer>
      </>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
`

const MainContainer = styled((props) => <Container {...props} />)`
  background-color: white;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
`
