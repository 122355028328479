import React from 'react'

export const Image: React.FC<{ basePath?: string; path: string }> = ({ basePath, path, ...props }) => {
  try {
    const image = require('../../assets/' + path + '@1x.png')
    const image2x = require('../../assets/' + path + '@2x.png')
    const image3x = require('../../assets/' + path + '@3x.png')

    const srcSet = `${image} 1x, ${image2x} 2x, ${image3x} 3x`
    return <img {...props} src={image} srcSet={srcSet} />
  } catch (e) {
    // we show error here. Otherwise react goes into loop
    // tslint:disable-next-line
    console.error(`Couldnt find image at path assets/${path}/Group@{1...3}.png" or one of sub-resolutions`)
    return <div />
  }
}
