import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Plus } from 'styled-icons/feather/Plus'

import { useAsyncEffect } from '../../utils/hooks/useAsyncEffect'
import { AccentButton } from '../Authorized/Buttons'
import { Heading } from '../Authorized/Headings'
import { ApiContext, Project } from '../context/Api'
import { AuthContext } from '../context/Auth'
import { ConfigContext } from '../context/Config'
import { ProjectListTable } from './ProjectListTable'

export const ProjectList: React.FC = observer(() => {
  const api = useContext(ApiContext)
  const auth = useContext(AuthContext)
  const config = useContext(ConfigContext)
  const [projects, setProjects] = useState<Project[] | undefined>()
  useAsyncEffect(async () => {
    if (!auth.org || !auth.jwt) {
      return
    }

    const projects = await api.getProjects(auth.jwt!, auth.org!.id)
    setProjects(projects)
  }, [auth.jwt, auth.org])
  const anyProjects = projects && projects.length > 0

  return (
    <>
      <Alert variant="info">
        👉 New to codechecks? Follow{' '}
        <a href="https://github.com/codechecks/docs/blob/master/getting-started.md">Getting Started</a> guide
      </Alert>
      <Alert variant="info">
        🔒 Looking for a private repo? Just send us an <a href="mailto:hello@codechecks.io">email</a> or hit us on{' '}
        <a href="https://twitter.com/codechecksio">twitter</a> to enable it
      </Alert>
      <Alert variant="info">
        👀 Can't find your project? Make sure to select correct organization in top right corner
      </Alert>
      <Row className="align-items-center">
        <Col>
          <Heading noMargin>Projects</Heading>
        </Col>
        <Col xs="auto">
          <AccentButton onClick={() => goTo(`https://github.com/apps/${config.github.appName}`)}>
            <PlusStyled />
            <span>Add new project</span>
          </AccentButton>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {anyProjects ? (
            <ProjectListTableStyled projects={projects!} />
          ) : (
            `No projects found. Use "Add new project" button.`
          )}
        </Col>
      </Row>
    </>
  )
})

const PlusStyled = styled((p) => <Plus size={30} {...p} />)`
  vertical-align: middle;
  margin-right: 8px;
`

const ProjectListTableStyled = styled(ProjectListTable)`
  margin-top: 30px;
`

function goTo(url: string) {
  ;(window as any).location = url
}
