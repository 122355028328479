import 'styled-components/macro'

import React, { useContext } from 'react'
import styled from 'styled-components'
import { Github } from 'styled-icons/fa-brands/Github'

import { Box, Container } from '../common/Grid'
import { Image } from '../common/Image'
import { ConfigContext } from '../context/Config'
import { getGithubOauthUrl } from '../context/urlHelpers'

export const LoginView: React.FC = () => {
  const config = useContext(ConfigContext)
  const githubLoginUrl = getGithubOauthUrl(config)

  return (
    <FullSizeContainer>
      <LoginCard>
        <LeftBox size={1}>
          <Box size={1}>
            <Image path="logo/logo" />
          </Box>

          <Heading>Welcome!</Heading>
          <Prompt>
            Open source platform for automated <Codechecks />. Pick from a vibrant ecosystem of already existing
            integrations to improve your code review workflow.
          </Prompt>
          <GithubButton to={githubLoginUrl} />
        </LeftBox>
        <Box size="270px">
          <Image path="pattern/pattern" />
        </Box>
      </LoginCard>
    </FullSizeContainer>
  )
}

const Codechecks = styled((p) => (
  <span {...p}>
    code<strong>checks</strong>
  </span>
))`
  color: #7857fb;
`

const FullSizeContainer = styled(Container)`
  height: 100%;
  align-items: center;
  justify-content: center;
`

const LoginCard = styled.div`
  display: flex;
  border-radius: 3px;
  box-shadow: 0px 3px 17px -7px rgba(0, 0, 0, 0.75);
  width: 650px;
  height: 400px;
`

const LeftBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 25px 35px;
`

const Heading = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #242121;
`

const Prompt = styled.p`
  margin-top: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
`

const GithubStyled = styled((p) => <Github size={33} {...p} />)`
  vertical-align: middle;
  margin-right: 5px;
`

const GithubButton = styled(({ to, ...p }: { to: string }) => (
  <a href={to}>
    <button type="button" {...p}>
      <GithubStyled /> <span css="vertical-align: middle"> Sign in with Github</span>
    </button>
  </a>
))`
  width: 320px;
  height: 58px;
  background-color: black;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.01em;
  text-decoration: none;
  margin-top: 15px;

  :hover {
    cursor: pointer;
  }
`
