import { HTTPError } from 'ky'

export async function tryParseErrorMessage(error: HTTPError) {
  const defaultError = error.message
  try {
    const body = await error.response.json()
    return body.message ?? defaultError
  } catch {
    return defaultError
  }
}
