import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export const AccentButton = styled(Button)`
  color: white;
  background: linear-gradient(90deg, #6c8afd 0%, #7857fb 100%);
  border-radius: 8px;
  line-height: 27px;
  font-size: 13px;
  font-weight: bold;

  & > * {
    vertical-align: middle;
  }
`

export const PlainButton = styled((props) => <Button variant="link" {...props} />)`
  color: #242121;
  border: 0;
  background-color: transparent;
  line-height: 27px;
  font-size: 13px;
  font-weight: bold;

  & > * {
    vertical-align: middle;
  }

  :hover {
    text-decoration: none;
  }
`
