import * as queryString from 'query-string'
import React, { useContext, useState } from 'react'
import { Alert } from 'react-bootstrap'
import useReactRouter from 'use-react-router'

import { useAsyncEffect } from '../../utils/hooks/useAsyncEffect'
import { Center } from '../common/Center'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { AuthContext } from '../context/Auth'
import { tryParseErrorMessage } from '../context/networking'

export const GithubCallback: React.FC = () => {
  const auth = useContext(AuthContext)
  const router = useReactRouter()
  const [error, setError] = useState<string | undefined>()

  useAsyncEffect(async () => {
    try {
      const qs = queryString.parse(router.location.search)
      const code = qs.code as string | undefined

      if (!code) {
        router.history.replace('/')
        return
      }

      await auth.authorizeUserTokenUsingGithub(code)
      router.history.replace(`/`)
    } catch (e) {
      setError(await tryParseErrorMessage(e))
    }
  })

  return <Center>{error ? <ErrorBanner errorMsg={error} /> : <LoadingIndicator />}</Center>
}

function ErrorBanner({ errorMsg }: { errorMsg: string }) {
  return (
    <Alert variant="danger">
      <p>
        Error occured. Try later, we are probably on it. If this keeps happening let us know on{' '}
        <a href="mailto:hello@codechecks.io">hello@codechecks.io</a>
      </p>

      <p>Reason: {errorMsg}</p>
    </Alert>
  )
}
