import { computed, observable } from 'mobx'
import * as mobx from 'mobx'
import { createContext } from 'react'

import { ApiService, apiService, JWT, Organization, User } from './Api'
import { Config, config } from './Config'

export class AuthService {
  constructor(private readonly api: ApiService, private readonly config: Config) {
    mobx.observe(this, 'jwt', async ({ newValue }) => {
      try {
        if (!newValue) {
          return
        }

        this.me = await this.api.getMe(newValue!)
        this.org = this.me.orgs.filter((o) => o.isDefaultOrg)[0]
      } catch (e) {
        console.error('Error while watching jwt. Clearing JWT.')
        this.jwt = undefined
      }
    })

    this.jwt = window.localStorage['jwt']

    // persist any changes to localstore
    // @todo extract
    mobx.observe(this, 'jwt', ({ newValue }) => {
      if (newValue === undefined) {
        console.info('Clearing JWT')

        window.localStorage.removeItem('jwt')
      } else {
        window.localStorage['jwt'] = newValue
      }
    })
  }

  public async authorizeUserTokenUsingGithub(sessionCode: string): Promise<void> {
    console.log('Authorizing user github!')
    const { jwt } = await this.api.authorizeUserTokenUsingGithub(sessionCode)
    this.jwt = jwt
  }

  public async authorizeUserProjectScopedTokenUsingGithub(sessionCode: string, projectId: string): Promise<void> {
    console.log('Authorizing user project scoped token using github!')
    const { jwt } = await this.api.authorizeUserProjectScopedTokenUsingGithub(sessionCode, projectId)
    this.jwt = jwt
  }

  @computed public get isAuthorized(): boolean {
    // @todo check JWT validity
    return !!this.jwt
  }

  public logout(): void {
    this.jwt = undefined
  }

  @observable public jwt: JWT | undefined
  @observable public me: User | undefined
  @observable public org: Organization | undefined
}

const authService = new AuthService(apiService, config)
export const AuthContext = createContext(authService)
