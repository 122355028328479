import ky from 'ky'
import { createContext } from 'react'
import { Opaque } from 'ts-essentials'

import { Config, getConfig } from './Config'

export class ApiService {
  private ky: typeof ky

  constructor(config: Config) {
    this.ky = ky.extend({ prefixUrl: config.api.url })
  }

  public async authorizeUserTokenUsingGithub(sessionCode: string): Promise<{ jwt: JWT }> {
    return await this.ky.post('gh/auth', { json: { code: sessionCode } }).json()
  }

  public async authorizeUserProjectScopedTokenUsingGithub(
    sessionCode: string,
    projectId: string,
  ): Promise<{ jwt: JWT }> {
    return await this.ky.post('gh/auth-project-scoped', { json: { code: sessionCode, projectId } }).json()
  }

  public async getMe(jwt: JWT): Promise<User> {
    return await this.ky
      .get('user/me', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .json()
  }

  public async getPrivateMe(jwt: JWT): Promise<User> {
    return await this.ky
      .get('user-private/me', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .json()
  }

  public getProjects(jwt: JWT, orgId: number): Promise<Project[]> {
    console.info(`Getting projects for (orgId: ${orgId})`)
    return this.ky
      .get('user/projects', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        searchParams: {
          orgId,
        },
      })
      .json()
  }
}

export interface User {
  login: string
  name?: string
  email: string
  avatarUrl: string

  orgs: Organization[]
}

export interface Project {
  name: string
  secret: string
  isPrivate: boolean
}

export interface Organization {
  id: number
  login: string
  avatarUrl: string
  isDefaultOrg: boolean
}

export type JWT = Opaque<'JWT', string>

export const apiService = new ApiService(getConfig())
export const ApiContext = createContext(apiService)
