import 'bootstrap/dist/css/bootstrap.css'
import './styles.css'
import type {} from 'styled-components/cssprop'

import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'

ReactDOM.render(<App />, document.getElementById('root'))
