import * as React from 'react'
import styled from 'styled-components'
import { Spinner2 as Spinner } from 'styled-icons/evil/Spinner2'

export const LoadingIndicator = styled((p) => <Spinner size={50} {...p} />)`
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  animation: spin 4s linear infinite;
  color: blue;
`
