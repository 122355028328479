import cx from 'classnames'
import copy from 'copy-to-clipboard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Copy } from 'styled-icons/feather/Copy'

import { PlainButton } from '../Authorized/Buttons'
import { Project } from '../context/Api'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  projects: Project[]
}

export const ProjectListTable: React.FC<Props> = (props) => {
  return (
    <div {...props}>
      <TableHeader>
        <Col xs={10}>Project Name</Col>
        <Col xs={2}>Secret</Col>
      </TableHeader>
      {props.projects.map((project, i) => (
        <TableRow project={project} key={i} />
      ))}
    </div>
  )
}

const TableHeader = styled(Row)`
  font-size: 12px;
  color: #afafaf;
  font-weight: 500;
  border-bottom: 1px #f7f7f7 solid;
  height: 30px;
`

const TableRow = styled((_props: { project: Project; className?: string }) => {
  const { project, className } = _props
  return (
    <Row className={cx('align-items-center', className)}>
      <Col xs={10}>
        <Name>{project.name}</Name>
        <Scope>{project.isPrivate ? 'Private' : 'Public'}</Scope>
      </Col>
      <Col xs={2}>
        <PlainButton
          onClick={() => {
            copy(project.secret)
            alert('Secret copied!')
          }}
        >
          <StyledCopy />
          <span>Copy</span>
        </PlainButton>
      </Col>
    </Row>
  )
})`
  border-bottom: 1px #f7f7f7 solid;
  padding-top: 10px;
  padding-bottom: 10px;
`

const Name = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 500;
`

const Scope = styled.span`
  font-size: 12px;
`

const StyledCopy = styled((props) => <Copy size={18} {...props} />)`
  vertical-align: middle;
  margin-right: 8px;
`
